<template>
  <b-col lg="12">
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label"> ID </label>
      <div class="col-sm-6">
        {{ user ? user.id :'' }}
      </div>
    </div>
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label"> Email </label>
      <div class="col-sm-6">
        {{ this.$route.params.id }}
      </div>
    </div>
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label">  Active </label>
      <div class="col-sm-6" v-if="user">
        <span v-if ="user.is_active" class="badge badge-pill badge-success">
          Active
        </span>
        
        <span v-else class="badge badge-pill badge-success">
          In Active
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label for="" class="col-sm-2 control-label"> Last Login </label>
      <div class="col-sm-6" v-if="user">
        {{ formatDate(user.last_login) }}
      </div>
    </div>
  </b-col>
</template>

<script>
import moment from "moment";
export default {
  name: "Profile",
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  },
}
</script>