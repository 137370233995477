var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" " + _vm._s(_vm.user ? _vm.user.id : '') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Email ")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" " + _vm._s(this.$route.params.id) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Active ")]), _vm.user ? _c('div', {
    staticClass: "col-sm-6"
  }, [_vm.user.is_active ? _c('span', {
    staticClass: "badge badge-pill badge-success"
  }, [_vm._v(" Active ")]) : _c('span', {
    staticClass: "badge badge-pill badge-success"
  }, [_vm._v(" In Active ")])]) : _vm._e()]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 control-label",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Last Login ")]), _vm.user ? _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.user.last_login)) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }