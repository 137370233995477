var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-right"
  }, [_c('b-col', {
    attrs: {
      "user": _vm.user
    }
  }, [_c('h4', {
    staticClass: "h4 text-center"
  }, [_vm._v("Single")]), _c('b-row', {
    staticClass: "justify-content-end pb-1"
  }, [_c('span', {
    staticClass: "pr-2"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-auto"
  }, [_vm._v("Buffets")]), _c('b-form-checkbox', {
    staticClass: "text-nowrap text-right",
    staticStyle: {
      "display": "table-cell"
    },
    on: {
      "change": function ($event) {
        return _vm.actionPremium();
      }
    },
    model: {
      value: _vm.selectPremium,
      callback: function ($$v) {
        _vm.selectPremium = $$v;
      },
      expression: "selectPremium"
    }
  }), _c('label', {
    staticClass: "col-sm-auto"
  }, [_vm._v("Single Owned")]), _c('b-form-checkbox', {
    staticClass: "text-nowrap text-right",
    staticStyle: {
      "display": "table-cell"
    },
    on: {
      "change": function ($event) {
        return _vm.actionNonPremium();
      }
    },
    model: {
      value: _vm.selectNonPremium,
      callback: function ($$v) {
        _vm.selectNonPremium = $$v;
      },
      expression: "selectNonPremium"
    }
  }), _c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.actionRefresh
    }
  }, [_c('em', {
    staticClass: "fa fa-refresh",
    staticStyle: {
      "width": "20px",
      "height": "25%"
    }
  })]), _c('label', {
    staticClass: "col-sm-auto"
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatNumber(this.countSingle)))])], 1)])]), _c('br'), _c('b-table', {
    attrs: {
      "items": _vm.UserItems,
      "fields": _vm.fields,
      "hover": "",
      "show-empty": "",
      "responsive": "lg",
      "sticky-header": "500px"
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")])];
      }
    }, {
      key: "cell(item_type)",
      fn: function (row) {
        return [_c('div', [_c('span', {
          staticClass: "badge badge-pill badge-primary d-flex justify-content-center"
        }, [_vm._v(" " + _vm._s(row.item.item.item_type ? row.item.item.item_type : "") + " ")])])];
      }
    }, {
      key: "cell(item_status)",
      fn: function (row) {
        return [_c('div', [_c('span', {
          staticClass: "badge badge-pill badge-primary d-flex justify-content-center"
        }, [_vm._v(" " + _vm._s(row.item.item ? row.item.item.item_status : "") + " ")])])];
      }
    }, {
      key: "cell(user_buffet_id)",
      fn: function (row) {
        return [row.item.user_buffet_id == null || row.item.user_buffet_id == 'null' ? _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ❌ ")]) : _c('span', {
          staticClass: "badge"
        }, [_vm._v(" ✅ ")])];
      }
    }, {
      key: "cell(orderline_id)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_vm._v(" " + _vm._s(row.item.orderline_id ? row.item.orderline_id : "") + " ")])];
      }
    }, {
      key: "cell(item_name)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b', [_vm._v(" " + _vm._s(row.item.item ? row.item.item.name : "") + " ")])])];
      }
    }, {
      key: "cell(preview_image)",
      fn: function (row) {
        return [_c('em', {
          staticClass: "fa fa-eye d-flex justify-content-center",
          attrs: {
            "id": "previewImageStyle"
          },
          on: {
            "click": function ($event) {
              return _vm.previewImage(row.item.item.thumb_image_normal, row.item.item.name);
            }
          }
        })];
      }
    }, {
      key: "cell(is_active_cell)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "size": "lg"
          },
          on: {
            "change": function ($event) {
              return _vm.updateIsActiveAction(row.item.id, row.item.is_active, row.item.user_id, row.item.orderline_id);
            }
          },
          model: {
            value: row.item.is_active,
            callback: function ($$v) {
              _vm.$set(row.item, "is_active", $$v);
            },
            expression: "row.item.is_active"
          }
        })], 1)];
      }
    }])
  }), _c('b-pagination', {
    attrs: {
      "per-page": _vm.perPage,
      "totalRows": this.countSingle,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }