<template>
  <div class= "text-right">
      <b-col :user="user">
        <h4 class="h4 text-center">Single</h4>
        <b-row class="justify-content-end pb-1">
          <span class="pr-2">
            <div class="form-group row">
              <label class="col-sm-auto">Buffets</label>
                <b-form-checkbox
                class="text-nowrap text-right"
                v-model="selectPremium"
                style="display: table-cell"
                @change="actionPremium()"
                >
              </b-form-checkbox>
              <label class="col-sm-auto">Single Owned</label>
              <b-form-checkbox
                class="text-nowrap text-right"
                v-model="selectNonPremium"
                style="display: table-cell"
                @change="actionNonPremium()"
                >
                </b-form-checkbox>
                <button class="btn btn-sm btn-dark " @click="actionRefresh">
                  <em style="width: 20px; height: 25%"  class="fa fa-refresh"></em>
                </button>
                <label class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">{{ formatNumber(this.countSingle) }}</strong>
            </div>
          </span>
        </b-row>
        <br/>
        <b-table
          :items="UserItems"
          :fields="fields"
          hover
          show-empty
          responsive="lg"
          sticky-header="500px"
          >
          <template #cell(No)="row">
            <div class=" d-flex justify-content-center">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
           </div>
          </template>

          <template #cell(item_type)="row">
            <div>
              <span  class="badge badge-pill badge-primary d-flex justify-content-center">
                
               {{ row.item.item.item_type ? row.item.item.item_type : "" }}
               </span>
            </div>
           </template>

           <template #cell(item_status)="row">
            <div>
              <span  class="badge badge-pill badge-primary d-flex justify-content-center">
               {{ row.item.item ? row.item.item.item_status : "" }}
              </span>
            </div>
           </template>

           <template #cell(user_buffet_id)="row">
              <span class="badge" style="color:red" v-if ="row.item.user_buffet_id == null || row.item.user_buffet_id == 'null'">
                &#10060;
              </span>
              <span v-else class="badge"> 
                &#9989;
              </span>
            </template>

           <template #cell(orderline_id)="row">
            <div class=" d-flex justify-content-center">
             {{ row.item.orderline_id ? row.item.orderline_id : "" }}
           </div>
          </template>
          
          <template #cell(item_name)="row">
            <div class=" d-flex justify-content-center">
              <b>
                {{ row.item.item ? row.item.item.name : "" }}
              </b>
            </div>
          </template>

          <template #cell(preview_image) ="row">
            <em class="fa fa-eye d-flex justify-content-center" id ="previewImageStyle"
              @click="previewImage(row.item.item.thumb_image_normal, row.item.item.name )"
            ></em>
          </template>
  
          <template #cell(is_active_cell)="row">
            <div class=" d-flex justify-content-center">
              <b-form-checkbox switch size="lg"
              v-model="row.item.is_active"
              @change="updateIsActiveAction(row.item.id,row.item.is_active, row.item.user_id, row.item.orderline_id)"
              ></b-form-checkbox>
            </div>
          </template>
        
        </b-table>
      <b-pagination
        v-model="currentPage"
        :per-page ="perPage"
        :totalRows = "this.countSingle"
        aria-controls="my-table"
      />
      </b-col>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import constant from '../../store/constant';
  import { mapActions, mapState } from "vuex";
  export default {
    name: "UserSingleNew",
    props: {
      user: {
        type: String,
        required: true,
      },
      UserItems: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },watch: {
      currentPage: function() {
        this.actionGetSingle();
      },
    },
    data() {
      return {
        show: false,
        modal: false,
        currentPage:1,
        perPage:10 ,
        selectPremium:false,
        selectNonPremium:false,
        selectAll: true,
        fields:[
          { key:'No', style:{'width':'5%', 'text-align': 'center'}, label:'No' },
          { key:'id', style:{'width':'5%', 'text-align': 'center'}, label:'User Items ID'},
          { key:'orderline_id', style:{'width':'5%', 'text-align': 'center'}, label:'Orderline Key'},
          { key:'item_name', style:{'width':'20%', 'text-align': 'center'}, label:'Items Name'},
          { key:'item_type', style:{'width':'5%', 'text-align': 'center'} , label:'Item Type'},
          { key:'item_status', style:{'width':'5%', 'text-align': 'center'} ,label:'Item Status'},
          { key:'user_buffet_id', style:{'width':'5%', 'text-align': 'center'} ,label:'Buffets'},
          { key :'created', label:'Created',  style:{'width':'5%', 'text-align': 'center'}, formatter: function(value) {
              return moment(value).format('DD MMM YYYY HH:mm:ss');
          },},
          { key :'modified',style:{'width':'5%', 'text-align': 'center'}, label:'Modified' ,formatter: function(value) {
              return moment(value).format('DD MMM YYYY HH:mm:ss');
          },},
          { key:'is_active_cell', style:{'width':'5%', 'text-align': 'center'},  label:'Is Active'},
          { key:'preview_image', style:{'width':'5%', 'text-align': 'center'}, label:'Preview Image'},
          
        ]
      };
    },
    computed: {
      ...mapState({
        isLoadingDelete: (state) => state.buffets.isLoadingDelete,
        message: (state) => state.buffets.message,
        countSingle: (state) =>state.users.countSingle,
      })
    },
    methods: {
      ...mapActions("users", ["fetchSingle"]),
      ...mapActions("single", ["deactiveOrders"]),
      showModal(item) {
        this.modal = true;
        this.BuffetsDetail = item;
      },
      actionPremium () {
        this.selectNonPremium = false;
        this.selectAll = false;
        this.actionGetSingle();
      },
      actionNonPremium () {
        this.selectPremium = false;
        this.selectAll = false;
        this.actionGetSingle();
      },
      actionRefresh() {
        this.selectPremium = false;
        this.selectNonPremium = false;
        this.selectAll = true;
        this.actionGetSingle();
      },
      previewImage(url, orderline_name){
        const removeBucket = this.removeBucket (url)
        const basePath = constant.URL_STATIC.EBOOK_COVER
        const image  = `${basePath}${removeBucket}`
        this.$swal.fire({
          title: orderline_name,
          imageUrl: image,
          imageWidth: 250,
          imageHeight: 500,
          imageAlt: 'Custom image',
        })
      },
      removeBucket(str) {
        if (str.substring(0, 6) == "images") {
          return str.substring(9);
        } else {
          return str;
        }
      },formatNumber(value) {
        if (value) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return '0';
      },
      updateIsActiveAction (id,is_active, user_id, orderline_id){
          let stringActive='';
          if(is_active){
            stringActive = 'Active';
          } else {
            stringActive = 'In Active'
          }
          this.$swal
          .fire({
            title: "Are you sure?",
            text: `You Want To Change Into ${stringActive}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
          }).then((result) => {
            if (result.isConfirmed) {
              let payload={
                id, is_active, user_id, orderline_id,
              }
              this.deactiveOrders(payload).then((response) => {
                const message = response.message;
                if(response.result){
                  this.baseAlert({
                    icon: 'success',
                    title: `Success ${stringActive} Items`,
                    text: message,
                  });
                }else{
                  this.baseAlert({
                    icon: 'error',
                    title: `Failed ${stringActive} Items`,
                    text: message,
                  });
                }
                this.actionGetSingle();
              });
            } else{
              this.actionGetSingle();
            }
          });
        },
        baseAlert(data) {
        this.$swal({
          icon: data.icon,
          title: data.title,
          text: data.text,
        })
      },
        actionGetSingle() {
        let email = this.user;
        let premium = this.selectPremium;
        let nonPremium = this.selectNonPremium;
        let selectAll = this.selectAll;
        if(premium == nonPremium) {
          selectAll = true;
        }
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
          premium,
          email,
          selectAll,
        };
        this.fetchSingle(payload);
      },
    },
  };
  </script>
2
  <style>
  #previewImageStyle {
    width: 0%;
    height:0%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  #previewImageStyle:hover {
    cursor: pointer; 
  }
  </style>
  