<template>
    <div>
      <h4 class="h4 text-center">Subscription</h4>
      <div v-if="isLoading">
        Loading...
      </div>
      <div v-else  class="subscripbe">
        <b-table
          :items="subscription"
          :fields="fields"
          hover
          show-empty
          responsive="lg"
        >
        <template #cell(No)="row">
         {{ row.index + 1 }}
        </template>
        <template #cell(orderlines)="row">
            <span>
              {{ rows.orderlines.offers.long_name ? row.orderlines.offers.long_name : "" }}
            </span>
        </template>
        <template #cell(status)="row">
          <div>
            <span
              v-if="isValid(row.item.valid_to)"
              class="text-primary"
            >
              Active
            </span>
            <span v-else class="text-danger">
              InActive
            </span>
          </div>
        </template>
        </b-table>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    name: "Subscription",
    props: {
      user: {
        type: String,
        required: true,
      },
      subscription: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        fields: [
          { key: "No", label: "No" },
          { key :'created', label:'Created',  style:{'width':'5%', 'text-align': 'center'}, formatter: function(value) {
              return moment(value).format('DD MMM YYYY');
          },},
          {key:"subscription_code", label:"Subscription Code"},
          { key:'orderlines.offers.long_name', label:' Offer Name '},
          { key :'valid_from', label:"Valid From", sortable: true, 
            style:{'width':'5%', 'text-align': 'center'},
            formatter: function(value) {
              return moment(value).format('DD MMM YYYY');
          },},
          { key :'valid_to', label:"Valid To", sortable: true, 
            style:{'width':'5%', 'text-align': 'center'}, 
            formatter: function(value) {
              return moment(value).format('DD MMM YYYY');
          },},
          { key:'status', label:'Status'},
        ],
      };
    },
    methods: {
      isValid(date) {
      let now = Date.now();
      if (moment(date).isSameOrAfter(now)) {
        return true;
      }

      return false;
    },
    }
  };
  </script>
  
  <style scoped>
  .subscripbe {
    max-height :600px;
    overflow-y: auto;
  }
  </style>
  