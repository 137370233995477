<template>
  <div>    
    <apexchart
      type="bar"
      height="350"
      :options="chartReading"
      :series="ReadingSeries"
    />
  </div>
</template>

<script>
import API from "../../plugins/http"
  export default {
    name:"PopularItemRead",
    props:{
      id: {
        type: Number,
        required: true
      },
      from: {
        type: String,
        required: true
      },
      to: {
        type: String,
        required: true
      }
    },
    data() {
      return{
        chartReading:{},
        ReadingSeries:[{ data: [],name:"Reading" }],
        items:[],
        duration:[],
        books:[]
      }
    },
    mounted() {
      this.actionGet()
    },
    methods: {
      actionGet() {
        API.get(`reading-durations-user/group/${this.id}?from=${this.from}&to=${this.to}&limit=10`).then(response => {
          this.items = response.data.data
          this.actionDestructor()
        })
      },
      SumDuration(items){
        let sum = 0
        items.forEach(e => {
          sum += e.duration
        })
        return sum
      
      },
      RemoveDuplicate(items){
        let unique = []
        items.forEach(e => {
          if(unique.find(d => d.item_id == e.item_id) == undefined){
            unique.push(e)
          }
        })
        return unique
      },
     
      actionDestructor(){
        let items = this.RemoveDuplicate(this.items)
        
        items.forEach(e => {
          let find = this.items.filter(i => i.item_id == e.item_id)
          if (find) {
            console.log(find.length)
            let d = this.SumDuration(find)
            this.books.push({
              name:e.item_name,
              isbn:e.gtin13,
              duration: d,
              item_type:find[0].item_type,
            })
          }
        });
        console.log(this.books)
        this.actionBuildChart()
      },
      actionBuildChart(){
        let book = this.books.map(i => i.name)
        this.books.sort((a,b) => (a.duration < b.duration) ? 1 : ((b.duration < a.duration) ? -1 : 0));
        let total = this.books.map(i => i.duration)
        
        this.ReadingSeries[0].data= total;
        this.chartReading = {
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
            },
            
          },
          colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
              
            },
            formatter: value => this.formatMinutes(value)
          },
          tooltip: {
            theme: "dark",
            dataLabels: true,
            y: {
              formatter: value => this.formatMinutes(value)
            }
          },
          title: {
            text: "History Reading" ,
            align: "left"
          },
          xaxis: {
            categories: book,
            align: "left",
            labels: {
              show: false,
              formatter: value => this.formatMinutes(value)
            }
          },
          yaxis: {
            labels: {
              show: true
            }
          },
        };
      },
      formatPriceBasic(value) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      formatMinutes(data) {
        let menit = parseInt(data) / 60

        if (data < 60) {
          return Math.round(data) + ' Second'
        } else if (menit > 59) {
          let hour = menit / 60
          return Math.round(hour) +' Hour'
        } else if (menit < 59){
          return Math.round(menit) + ' Minute'
        } 

      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>