var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartReading,
      "series": _vm.ReadingSeries
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }