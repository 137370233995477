var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-1"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "show-empty": "",
      "responsive": "sm",
      "hover": "",
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sticky-header": "550px"
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }