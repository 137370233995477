<template>
  <div>
    <b-col :user="user">
      <h4 class="h4 text-center">Buffets</h4>
      <b-alert show variant="warning">
        Action Delete Can be only One by One</b-alert
      >
      <b-table
        :items="buffets"
        :fields="fields"
        hover
        show-empty
        responsive="lg"
        sticky-header="500px"
        >
        <template #cell(No)="row">
         {{ row.index + 1 }}
        </template>
        <template #cell(created)="row">
         <div>
           {{ formatDate(row.item.created) }}
         </div>
        </template>
        <template #cell(valid_to)="row">
         <div>
           {{ formatDate(row.item.valid_to) }}
         </div>
        </template>
        <template #cell(order)="row">
         <div>
            {{ row.item.orderlines ? row.item.orderlines.order_id : "" }}
         </div>
        </template>
        <template #cell(orderlinekey)="row">
         <div>
           {{ row.item.orderlines ? row.item.orderlines.id : "" }}
         </div>
        </template>
        <template #cell(orderlines)="row">
         <b>
           {{ row.item.orderlines ? row.item.orderlines.name : "" }}
         </b>
        </template>
        <template #cell(is_restore)="row">
          <div>
            <button v-if="row.item.is_restore ==true"
             class="btn btn-sm btn-outline-primary">
              <i class="fa fa-check"></i>
            Restore </button>
          </div>
        </template>
        <template #cell(status)="row">
          <div>
            <span
              v-if="isValid(row.item.valid_to)"
              class="btn btn-md btn-success"
            >
              Active
            </span>
            <span v-else class="btn btn-md btn-danger">
              InActive
            </span>
          </div>
        </template>
        <template #cell(edit)="row">
          <button class="btn btn-md btn-default" @click="showModal(row.item)">
            <i class="fa fa-pencil"></i>
          </button>
        </template>
      </b-table>
    </b-col>
    <b-col>
      <b-modal
        id="update-buffets"
        size="md"
        title="update buffets"
        header-bg-variant="info"
        centered
        :hide-footer="true"
        v-model="modal"
      >
        <b-form @submit="onSubmit" @reset="onReset">
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="input-valid-to"
                label="Valid To"
                label-for="Valid to"
                label-cols="2"
                content-cols="10"
              >
                <v-date-picker v-model="date" mode="date">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      style="width:80%"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <b-button
                v-if="date"
                class="mr-3  text-white"
                type="submit"
                variant="info"
              >
                Update
              </b-button>
              <b-button class="text-white" type="reset" variant="secondary">
                Cancel
              </b-button>
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "UserBuffets",
  props: {
    user: {
      type: String,
      required: true,
    },
    buffets: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      modal: false,
      BuffetsDetail: {},
      date: new Date().toString(),
      fields:[
        { key:'No', label:'No' },
        { key:'id', label:'Buffet ID', sortable: true},
        { key:'created', label:'Created'},
        { key:'valid_to', label:'Valid To',sortable: true},
        { key:'order', label:'Order ID'},
        { key:'Orderlinekey', label:'Orderline Key',variant: 'info'},
        { key:'original_transaction_id', label:'original Transaction Id'},
        { key:'orderlines', label:'Name Offers' , style:{'width':'20%'}},
        { key:'is_restore', label:'Restore'},
        { key:'status', label:'Status'},
        { key:'edit', label:'Edit'},
        { key:'is_restore', label:'Restore'},
        { key:'status', label:'Status'},
        { key:'edit', label:'Edit'},
        { key:'status', label:'Status'},
        { key:'created', label:'Update'},
        { key:'edit', label:'Edit'},
      ]
    };
  },
  computed: {
    ...mapState({
      isLoadingDelete: (state) => state.buffets.isLoadingDelete,
      message: (state) => state.buffets.message,
    }),
  },
  methods: {
    ...mapActions("users", ["fetchBuffets"]),
    ...mapActions("buffets", ["updateBuffetByid", "buffetDelete"]),
    showModal(item) {
      this.modal = true;
      this.BuffetsDetail = item;
    },
    actionGetBuffet() {
      let email = this.user;
      let data = {
        email: email,
      };
      this.fetchBuffets(data);
    },
    formatDate(tgl) {
      if (tgl) {
        return moment(tgl).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    onReset() {},
    async onSubmit(e) {
      e.preventDefault();
      let payload = {
        valid_to: moment(this.date).format("YYYY-MM-DD hh:mm:ss"),
        id: this.BuffetsDetail.id,
        user_id: this.BuffetsDetail.user_id,
        orderline_id: this.BuffetsDetail.orderlines
          ? this.BuffetsDetail.orderlines.id
          : 0,
      };

      try {
        const result = await this.updateBuffetByid(payload);
        if (result) {
          this.actionGetBuffet();
          this.$swal({
            toast: "true",
            position: "top-end",
            icon: "success",
            title: "Success Update",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        this.$swal({
          type: "error",
          text: error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.modal = false;
    },
    isValid(date) {
      let now = Date.now();
      if (moment(date).isSameOrAfter(now)) {
        return true;
      }

      return false;
    },
    async actionDelete(line) {
      this.showConfirmModal(line);
    },
    showConfirmModal(line) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.ExecuteDelete(line);
            console.log(line)
            // this.$swal({
            //   type: "error",
            //   text: "Sory this service in not active now",
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 3000,
            // });
          }
        });
    },
    async ExecuteDelete(line) {
      try {
        let payload = {
          user_id: line.user_id,
          id: line.id,
          orderline_id: line.orderlines ? line.orderlines.id : null,
        };
        console.log(payload);
        let result = await this.buffetDelete(payload);

        if (result) {
          if (result.data.status == 200) {
            this.$swal({
              toast: "true",
              position: "top-end",
              icon: "success",
              title: "Patch successfully",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            let data = result.data;
            this.$swal({
              toast: "true",
              position: "top-end",
              icon: "success",
              title: JSON.stringify(data),
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      } catch (err) {
        this.$swal({
          toast: "true",
          position: "top-end",
          icon: "success",
          title: this.message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
