<template>
  <div class="mb-1">
    <b-table
      :items="items"
      :fields="fields"
      :busy="isLoading"
      show-empty
      responsive="sm"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sticky-header="550px"
    >
      <template #cell(No)="row">
        {{ row.index + 1 }}
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name:'UserItunes',
    props: {
      items: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        sortBy: 'expires_date',
        sortDesc: false,
        fields: [
          { key:'No', label: 'No'},
          { key:'product_id', label: 'Product'},
          { key:'quantity', label: 'Quantity'},
          { key:'transaction_id', label: 'Transaction Id'},
          { key:'original_transaction_id', label: 'Original Transaction Id'},
          { key:'original_transaction_id', label: 'Original Transaction Id'},
          { key:'purchase_date', label: 'Purchase Date', sortable: true, variant:'info'},
          { key:'expires_date', label: 'Expire date'},
          { key:'in_app_ownership_type', label: 'In app'},
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>