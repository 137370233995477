var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "h4 text-center"
  }, [_vm._v("Subscription")]), _vm.isLoading ? _c('div', [_vm._v(" Loading... ")]) : _c('div', {
    staticClass: "subscripbe"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.subscription,
      "fields": _vm.fields,
      "hover": "",
      "show-empty": "",
      "responsive": "lg"
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(orderlines)",
      fn: function (row) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.rows.orderlines.offers.long_name ? row.orderlines.offers.long_name : "") + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('div', [_vm.isValid(row.item.valid_to) ? _c('span', {
          staticClass: "text-primary"
        }, [_vm._v(" Active ")]) : _c('span', {
          staticClass: "text-danger"
        }, [_vm._v(" InActive ")])])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }